<template>

    <div class="shadow-lg p-3 mb-5 mt-5 container">
        <h1>Schützenbuch <img
            src="/Bilder/book.png"
            width="100"
            height="100"
            class="rounded-circle"
        /></h1>


        <br>
        <h3>Impressum</h3>
        <p>
            Matthias Aßmann<br>
            Dijonstraße 63 <br>
            55122 Mainz <br> <br>
            info(at)schuetzenbuch.de<br>
        </p>
    </div>

</template>

<script>
export default {
    name: "about"
}
</script>

<style scoped>

</style>
