var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4  px-5"},[_c('h2',[_vm._v("Feedback einreichen")]),_c('editor',{attrs:{"api-key":"3yqivr09xo7i95krxcytn121zfdzpwvp5n1e1l7glpf1zs21","output-format":"html","init":{
       height: 500,
       menubar: true,
       // plugins: [
       //   'advlist autolink lists link image charmap print preview anchor',
       //  'searchreplace visualblocks code fullscreen',
       //  'insertdatetime media table paste code help wordcount'
       // ],
       toolbar:
         'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help'
     }},model:{value:(_vm.TextMail),callback:function ($$v) {_vm.TextMail=$$v},expression:"TextMail"}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogSend),callback:function ($$v) {_vm.dialogSend=$$v},expression:"dialogSend"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Willst du wirklich senden?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialoge}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.sendMail}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('v-btn',{staticClass:"mt-5",attrs:{"color":"primary"},on:{"click":_vm.sendMail_Confirm}},[_c('i',{staticClass:"far fa-save"}),_vm._v(" senden")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }